.root {
  display: flex;
  flex-direction: column;
}

.navLink {
  display: flex;
  flex-direction: column;
  color: black;
  font-family:
    'Saira Extra Condensed',
    Helvetica Neue,
    Helvetica,
    Arial,
    sans-serif;
  font-size: 18px;
  letter-spacing: 4.9px;
  text-decoration: none;
  text-transform: uppercase;
}

.navLink:hover,
.navLinkActive {
  border-bottom: 1px solid #25a6bd;
}

.mobileNavLink {
  letter-spacing: 2.9px;
  padding: 0.5rem 1rem;
  flex: 1;
}

.mobileNavLink:hover,
.mobileNavLinkActive {
  background-color: #25a6bd77;
}
